/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
/* eslint-disable no-bitwise */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
// @flow

import React, { Fragment } from 'react';
import Tooltip from 'rc-tooltip';
import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap.css';
import { FORM_ROOT_PAGE_ROUTE } from '../../../const';
import type { ContractListRS, Coverage, UnderwritingConditions } from '../../../types/QuotationTypes';
import { convertHex } from '../../../utils';
import { buildFrontPath } from '../../../routes';
import { displayPrice, extractContractIptTax } from '../../../network/api/eqsApi';
import TermsAndConditions from './TermsAndConditions';
import type { DCXType } from '../../../../../admin-ui/src/types/DCXTypes';

type Props = {
  contract: ContractListRS,
  quoteFormFront: Object,
  history: Object,
  quotation: Object,
  match: Object,
  color: string,
  quotationIptTaxesLabel: ?string,
  showTaxes: boolean,
  DCX: DCXType,  
  IsPromoEnable: bool,
  language: string,
}
type State = {
  detailOpen: boolean
}
type InfoDetail = {
  name: string,
  htmlValue: ?string,
  description: ?string,
}

const errorMessages = {
  errMsgIncorrect: {
    en: "The promo code you entered is incorrect or does not exist",
    de: "Der von Ihnen eingegebene Aktionscode ist falsch oder existiert nicht",
    fr: "Le code promotionnel que vous avez saisi est incorrect ou n'existe pas",
    it: "Il codice promozionale inserito non è corretto o non esiste",
    cy: "Mae'r cod hyrwyddo a fewnodd yn anghywir neu nid yw'n bodoli",
    el: "Ο κωδικός προσφοράς που εισαγάγατε είναι λανθασμένος ή δεν υπάρχει.",
    zh: "您输入的促销代码不正确或不存在。",
    es: "El código de promoción que ingresó es incorrecto o no existe",
    ar: "رمز الترويج الذي أدخلته غير صحيح أو غير موجود.",
    cs: "Propagační kód, který jste zadali, je nesprávný nebo neexistuje",
    da: "Promokoden, du indtastede, er forkert eller eksisterer ikke",
    fi: "Syöttämäsi kampanjakoodi on virheellinen tai ei ole olemassa",
    nl: "De promotiecode die u heeft ingevoerd is onjuist of bestaat niet",
    pl: "Kod promocyjny, który wprowadziłeś, jest nieprawidłowy lub nie istnieje",
    pt: "O código promocional que você inseriu está incorreto ou não existe",
    ro: "Codul promoțional introdus este incorect sau nu există",
    sv: "Kampanjekoden du angav är felaktig eller existerar inte",
    hu: "A promóciós kód, amit megadott, helytelen vagy nem létezik",
    bg: "Промоционалният код, който въведохте, е неправилен или не съществува",
    ru: "Введённый вами промокод неверен или не существует",
    no: "Kampanjekoden du skrev inn er feil eller finnes ikke",
    id: "Kode promosi yang Anda masukkan salah atau tidak ada",
    ca: "El codi de promoció que heu introduït és incorrecte o no existeix"
  },
  errMsgApplicable: {
    en: "The promotion code is not applicable",
    de: "Der Aktionscode ist nicht anwendbar",
    fr: "Le code promotionnel n'est pas applicable",
    it: "Il codice promozionale non è applicabile",
    cy: "Nid yw'r cod hyrwyddo yn berthnasol",
    el: "Ο κωδικός προσφοράς δεν ισχύει",
    zh: "促销代码不适用",
    es: "El código de promoción no es aplicable",
    ar: "رمز الترويج غير قابل للتطبيق",
    cs: "Propagační kód není použitelný",
    da: "Promokoden gælder ikke",
    fi: "Kampanjakoodi ei ole voimassa",
    nl: "De promotiecode is niet van toepassing",
    pl: "Kod promocyjny nie ma zastosowania",
    pt: "O código promocional não é aplicável",
    ro: "Codul promoțional nu este aplicabil",
    sv: "Kampanjekoden är inte giltig",
    hu: "A promóciós kód nem alkalmazható",
    bg: "Промоционалният код не е приложим",
    ru: "Промокод не применим",
    no: "Kampanjekoden er ikke gyldig",
    id: "Kode promosi tidak berlaku",
    ca: "El codi de promoció no és aplicable"
  }
};

const toDetailArray = (coverages: ?Coverage[], underwritingConditions: ?UnderwritingConditions[]) => {
  const details: InfoDetail[] = [];
  if (coverages) {
    for (const coverage of coverages) {
      details.push({
        name: coverage.name,
        htmlValue: coverage.coverageContent,
        description: coverage.description,
      });
    }
  }
  if (underwritingConditions) {
    for (const condition of underwritingConditions) {
      details.push({
        name: condition.shortDescription,
        htmlValue: condition.underwritingCondition,
        description: condition.description,
      });
    }
  }
  return details;
};

class ProductOffer extends React.Component<Props, State> {
  state = {
    detailOpen: false,
  };

  validateHasDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length > 0) {
      if (promotionCode && discountAmount > 0 && totalDiscountAmount > 0 && grossPremium < originalGrossPremium) {
        result = true;
      }    
    }   
    /*
    console.log( "HasDiscount: " + result      
      + " | IsPromoEnable: " + this.props.IsPromoEnable    
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );     
    */
    return result;
  }

  validateHasZeroDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length > 0) {
      if (promotionCode && discountAmount === 0 && totalDiscountAmount === 0 && grossPremium === originalGrossPremium) {
        result = true;
      }    
    }
    /*
    console.log( "HasZeroDiscount: " + result    
      + " | IsPromoEnable: " + this.props.IsPromoEnable      
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );
    */
    return result;
  }
  
  validateHasNoDiscount = (contract : ContractListRS) => {
    let result = false;
    const promotionCode = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountCode : "[Empty]";
    const discountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].discountAmount.amount : "[Empty]";
    const totalDiscountAmount = (this.props.contract.recommendedPremium.discounts.length > 0) ? this.props.contract.recommendedPremium.discounts[0].totalDiscountAmount.amount : "[Empty]";    
    const grossPremium = this.props.contract.recommendedPremium.grossPremium.amount;
    const originalGrossPremium = this.props.contract.recommendedPremium.originalGrossPremium.amount;
    const currency = this.props.contract.recommendedPremium.originalGrossPremium.currency;  

    if (!this.props.IsPromoEnable) return result;
    if (this.props.contract.recommendedPremium.discounts.length === 0) {            
      if (grossPremium === originalGrossPremium) {
        result = true;
      }   
    }    
    /*
    console.log( "HasNoDiscount: " + result         
      + " | IsPromoEnable: " + this.props.IsPromoEnable 
      + " | promotionCode: " + promotionCode 
      + " | discountAmount: " + discountAmount
      + " | totalDiscountAmount: " + totalDiscountAmount
      + " | originalGrossPremium: " + originalGrossPremium
      + " | grossPremium: " + grossPremium
      + " | currency: " + currency
    );
    */
    return result;
  }  

  render() {
    const iptTax = extractContractIptTax(this.props.contract);
    const detailsArray = toDetailArray(this.props.contract.coverages, this.props.contract.underwritingConditions);
    const color = this.props.color;
    const colorLight = convertHex(color, 40);

    console.clear();

    const promotionCode = (this.props.quoteFormFront.promoCode) ? this.props.quoteFormFront.promoCode : "[Empty]";
    const HasDiscount = this.validateHasDiscount(this.props.contract); 
    const HasZeroDiscount = this.validateHasZeroDiscount(this.props.contract);  
    const HasNoDiscount = this.validateHasNoDiscount(this.props.contract);  

    console.log( "HasDiscount: " + HasDiscount               
      + " | HasNoDiscount: " + HasNoDiscount
      + " | HasZeroDiscount: " + HasZeroDiscount      
    );

    return (
      <Fragment>
        <div className="offer-container" style={{ background: color }}>
          <div className="title">
            {this.props.contract.productOfferingName}
          </div>
          <div className="right-container">
            <div className="price-container">
              {                
                !this.props.IsPromoEnable &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }
              {
                this.props.IsPromoEnable && HasNoDiscount &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }      
              {
                this.props.IsPromoEnable && HasZeroDiscount &&
                <span className="price">
                  {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} {this.props.contract.recommendedPremium.grossPremium.currency}
                </span>
              }     
              {
                this.props.IsPromoEnable && HasDiscount && 
                  <span className="price">
                    {displayPrice(this.props.contract.recommendedPremium.grossPremium.amount)} / <s>{  displayPrice(this.props.contract.recommendedPremium.originalGrossPremium.amount) }</s> {this.props.contract.recommendedPremium.grossPremium.currency}
                  </span>
                
              }                                    
              {
                this.props.showTaxes &&
                iptTax &&
                <span className="ipt-tax">
                  {this.props.quotationIptTaxesLabel} {displayPrice(iptTax.totalTaxAmount.amount)} {iptTax.totalTaxAmount.currency}
                </span>
              }
            </div>
            <button
              className="button"
              onClick={() => {
                localStorage.setItem('contract', JSON.stringify(this.props.contract));
                this.props.history.push({
                  pathname: buildFrontPath(this.props.match.params, `${FORM_ROOT_PAGE_ROUTE}/${this.props.nextStep}`),
                  search: window.location.search,
                  state: {
                    quoteFormFront: this.props.quoteFormFront,
                    contract: this.props.contract,
                    DCX: this.props.DCX,
                  },
                });
              }
              }
            >
              {this.props.quotation.select}
            </button>
          </div>
        </div>
        <div className="details-container">
          <div
            className="show-details"
            style={{ color: color, background: colorLight }}
            onClick={() => this.setState(prevState => ({ detailOpen: !prevState.detailOpen }))}
          >
            {
              this.state.detailOpen ?
                <Fragment>
                  <i className="fa fa-minus-circle" />{' '}
                  {this.props.quotation.replysDetails}
                </Fragment>
                :
                <Fragment>
                  <i className="fa fa-plus-circle" />{' '}
                  {this.props.quotation.viewDetails}
                </Fragment>

            }

          </div>
          <div className={this.state.detailOpen ? 'open' : 'closed'} style={{ border: `1px solid ${colorLight}` }}>
            {
              detailsArray.map((detail, index) => (
                <div className="details" style={index & 1 ? { backgroundColor: colorLight } : {}} key={detail.name}>
                  <div className="name">{detail.name}
                    {detail.description &&
                    <Tooltip
                      placement="right"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0.1}
                      trigger={['hover']}
                      overlay={<div>{detail.description}</div>}
                    >
                      <i className="fa fa-info-circle" />
                    </Tooltip>
                    }
                  </div>
                  <div className="content" dangerouslySetInnerHTML={{ __html: detail.htmlValue }} />
                </div>
              ))
            }
          </div>
          <div style={{ background: color }}>
            <TermsAndConditions offer={this.props.contract} quoteSummaryTranslation={this.props.siteData.quoteForm.quoteSummaryTranslation} />
          </div>
          {
            this.props.IsPromoEnable && !HasZeroDiscount && !HasDiscount && HasNoDiscount && promotionCode !== "[Empty]" &&
            <div className="show-promo-error" style={{ color: color, background: colorLight }} >          
              { errorMessages.errMsgIncorrect[this.props.language] } 
            </div>
          }           
          {
            this.props.IsPromoEnable && !HasNoDiscount && !HasDiscount && HasZeroDiscount &&
            <div className="show-promo-error" style={{ color: color, background: colorLight }} >          
              { errorMessages.errMsgApplicable[this.props.language] } 
            </div>
          }           
        </div>
      </Fragment>
    );
  }
}

export default connect(state => ({
  DCX: state.site.DCX,
  siteData: state.site.config,
}))(ProductOffer);
